/* FullScreenImage.scss */

.full-screen-image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  cursor: zoom-out;
}

.full-screen-image-container {
  max-width: 90%;
  max-height: 90%;
}

.full-screen-image-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
